@import "styles/app.scss";

.card {
  max-width: 437px;
  max-height: 450px;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  color: $colorWhite;
  a {
    display: block;
    position: relative;
    height: 100%;
  }
  
  aspect-ratio: 343 / 343;
  @include mediaLg {
    aspect-ratio: 437 / 450;
  }

  @include mediaXx {
    max-width: 528px;
    max-height: 544px;
  }
  &::after,
  &::before {
    position: absolute;
    width: 100%;
    content: "";
    z-index: 2;
    opacity: 1;
    transition: opacity 0.5s;
  }
  &::after {
    @include darkGradient;
    height: 150px;
    bottom: 0;
    opacity: 0;
  }
  &::before {
    height: 100%;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  &:hover {
    &::after {
      opacity: 1;
    }
    &::before {
      opacity: 0;
    }
    .card__logo {
      opacity: 0;
    }
    .card__image {
      scale: 1.1;
    }
    .card__top {
      opacity: 1;
    }
    .card__bottom {
      opacity: 1;
    }
  }
  &__logo {
    z-index: 3;
    transition: opacity 0.5s;
    opacity: 1;
  }
  &__image {
    transition: scale 0.4s ease;
    object-fit: cover;
  }
  &__top {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    padding: 10px 20px;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(9px);
    opacity: 0;
    transition: opacity 0.5s;
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
    margin: 0 20px 29px;
    opacity: 0;
    transition: opacity 0.5s;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: calc(100% - 40px);
  }
  &__bottomRight {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
